import React from 'react';
import './Home.css'

import Phone from './Phone'

export default class Home extends React.Component {
    render() {
        return (
            <Phone/>
        );
    }
}